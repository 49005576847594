@import "/src/_styles/mixin";

:root {
  --white-color: #fff;
  --light-red-color: #eb5757;
  --primary-green: #00cc83;
  --light-black: rgba(0, 0, 0, 0.7);
  --light-gray-color: #f5f5f5;
  --border-color: #c4c4c4;
  --text-color: #4d4d4d;
  --bg-light-blue-color: #e7fbf8;
  --text-hover-color: #00b9b7;
  --text-hover-color-dark: #039390;
  --bg-course-color: #cff6f1;
  --black-color: #000;
  --light-yellow: #fdcd04;
  --title-assign-color: #00beb4;
  --gray-color: #bcbec0;
  --light-border-color: #d9d9d9;
  --linear-color: linear-gradient(270deg, #00e2a0 0%, #00b9b7 100%);
}

canvas,
img,
video {
  max-width: 100%;
  height: auto;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: black;
}

img {
  user-select: none;
}

.display_flex {
  display: flex;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-align-end {
  display: flex;
  align-items: flex-end;
}

.flex-align-start {
  display: flex;
  align-items: flex-start;
}

.flex-jus-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.flex-jus-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-start-jus-between {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.flex-just-center {
  display: flex;
  justify-content: center;
}

.flex-jus-end-between {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.flex-jus-start-between {
  display: flex;
  justify-content: space-between;
}

.flex-jus-start-evenly {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}

.flex-jus-evenly {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.flex-jus-end {
  display: flex;
  justify-content: flex-end;
}

.flex-align {
  display: flex;
  align-items: center;
}

.flex-self-center {
  display: flex;
  align-self: center;
  justify-self: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-center-important {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-all-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex-center-column {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flex-align-end-column {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}

.flex-align-start-column {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.flex-jus-end-column {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}

.flex-end-column {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
}

.flex-column-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
}

.flex-column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
}

.flex-column-center-cross-axis {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-column-jus-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.commondPage div,
.commondPage span,
.commondPage a,
.commondPage p,
.commondPage strong,
.commondPage input,
.commondPage textarea,
.commondPage th,
.commondPage td,
.commondPage h2,
.header_container div,
.footer_container div,
.footer_container span,
.footer_container a {
  font-family: "AvertaStdCY";
  color: var(--text-color);
}

.commondPage * {
  scroll-margin-top: 70px;
}

.commondPage input::placeholder,
.commondPage textarea::placeholder {
  font-family: "AvertaStdCY";
}

.selection_assign div,
.selection_assign span,
.selection_assign a {
  color: var(--text-color);
}

.footer_container a:hover {
  color: var(--text-hover-color) !important;
}

.footer_1_tail div,
.footer_1_tail a {
  color: var(--light-black);
  margin-bottom: 0.4rem;
  color: var(--text-color);

  @include screen_mobile {
    margin-bottom: 0.5rem;
  }
}

// Font Family
.font_news {
  font-family: "AvertaStdCY" !important;
}

.font_news_black {
  font-family: "AvertaStdCY-Black" !important;
}

.font_news_extra_bold {
  font-family: "AvertaStdCY-ExtraBold" !important;
}

.font_news_semi_bold {
  font-family: "AvertaStdCY-SemiBold" !important;
}

.font_news_italic {
  font-family: "AvertaStdCY-Italic" !important;
}

.font_news_bold {
  font-family: "AvertaStdCY-Bold" !important;
}

.font_myriad_bold {
  font-family: "Myriadpro-Bold" !important;
  line-height: normal;
  position: relative;
  font-weight: 700;
  top: 0.155rem;
}

.font_myriad_bold_no_top {
  font-family: "Myriadpro-Bold" !important;
  line-height: normal;
  color: var(--black-color);
}

.font_myriad_semi_bold {
  font-family: "Myriadpro-SemiBold" !important;
  line-height: normal;
  position: relative;
  top: 0.075rem;
}

.font_myriad_semi_bold_normal {
  font-family: "Myriadpro-SemiBold" !important;
  line-height: normal;
  position: relative;
}

.font_myriad_light {
  font-family: "Myriadpro-Light" !important;
  line-height: normal;
}

// COLORS
.color-linear {
  background: -webkit-linear-gradient(#00e2a0 0%, #00b9b7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.color-red {
  color: var(--light-red-color) !important;
}

.color-light-black {
  color: var(--light-black) !important;
}

.color-primary-green {
  color: var(--primary-green) !important;
}

.color-light-text {
  color: var(--border-color) !important;
}

.color-white {
  color: var(--white-color) !important;
}

.color-text {
  color: var(--text-color) !important;
  -webkit-text-fill-color: var(--text-color);
}

.color-black {
  color: var(--black-color) !important;
}

.color-light-blue {
  color: var(--text-hover-color) !important;
}

.color-title-assign {
  color: var(--title-assign-color) !important;
}

.color-gray {
  color: var(--gray-color) !important;
}

.stroke_primary_green {
  stroke: #00cc83 !important;
}

// Font Weight
.font-weight-500 {
  font-weight: 500;
}

.font-weight-700 {
  font-weight: 700;
}

// MARGIN
.mg-vertical-0-5 {
  margin: 0.5rem 0 !important;
}

.mg-horizontal-2 {
  margin: 0 2rem !important;
}

.mg-horizontal-1 {
  margin: 0 1rem !important;
}

.mg-bottom-2 {
  margin-bottom: 2rem !important;
}

.mg-bottom-15 {
  margin-bottom: 1.5rem !important;
}

.mg-bottom-12 {
  margin-bottom: 1.2rem;
}

.mg-bottom-1 {
  margin-bottom: 1rem;
}

.mg-bottom-0-3 {
  margin-bottom: 0.3rem;
}

.mg-bottom-0-5 {
  margin-bottom: 0.5rem;
}

.mg-bottom-0-7 {
  margin-bottom: 0.7rem;
}

.mg-bottom-0-9 {
  margin-bottom: 0.9rem;

  @include screen_mobile {
    margin-bottom: 0.3rem;
  }
}

.mg-bottom-0-8 {
  margin-bottom: 0.8rem;

  @include screen_mobile {
    margin-bottom: 0.3rem;
  }
}

.mg-left-0-5 {
  margin-left: 0.5rem;
}

.mg-left-1 {
  margin-left: 1rem;
}

.mg-left-15 {
  margin-left: 1.5rem;
}

.mg-right-0-5 {
  margin-right: 0.5rem;
}

.mg-right-0-75 {
  margin-right: 0.75rem;
}

.mg-right-1 {
  margin-right: 1rem;
}

.mg-right-3per {
  margin-right: 3%;
}

.mg-right-2 {
  margin-right: 2rem;
}

.mg-right-25 {
  margin-right: 2.5rem;
}

.mg-right-5 {
  margin-right: 5rem;
}

.mg-left-2 {
  margin-left: 2rem;
}

.mg-top-1 {
  margin-top: 1rem;
}

.mg-top-0-8 {
  margin-top: 0.8rem;

  @include screen_mobile {
    margin-top: 0.3rem;
  }
}

.mg-top-0-5 {
  margin-top: 0.5rem;
}

.mg-top-0-75 {
  margin-top: 0.75rem;
}

.mg-top-2 {
  margin-top: 2rem;
}

.mg-top-15 {
  margin-top: 1.5rem;
}

.mg-top-25 {
  margin-top: 2.5rem;
}

.mg-top-25 {
  margin-top: 2.5rem;
}

// Padding
.pd-bottom-0-3 {
  padding-bottom: 0.3rem;
}

.pd-bottom-0-5 {
  padding-bottom: 0.5rem;
}

.pd-bottom-0-6 {
  padding-bottom: 0.6rem;
}

.pd-bottom-0-7 {
  padding-bottom: 0.7rem;
}

.pd-bottom-1 {
  padding-bottom: 1rem;
}

.pd-bottom-15 {
  padding-bottom: 1.5rem;
}

.pd-bottom-17-5 {
  padding-bottom: 1.75rem;
}

.pd-bottom-2 {
  padding-bottom: 2rem;
}

.pd-bottom-3 {
  padding-bottom: 3rem;
}

.pd-top-0-5-pc {
  padding-top: 0.5rem;
}

.pd-top-0-75-pc {
  padding-top: 0.75rem;
}

.pd-top-1-pc {
  padding-top: 1rem;
}

.pd-top-12-5-pc {
  padding-top: 1.25rem;
}

.pd-top-15-pc {
  padding-top: 1.5rem !important;
}

.pd-top-2-pc {
  padding-top: 2rem;
}

.pd-top-2-5-pc {
  padding-top: 2.5rem;
}

.pd-top-3-pc {
  padding-top: 3rem;
}

.pd-top-4-pc {
  padding-top: 4rem;
}

.pd-left-05-pc {
  padding-left: 0.5rem;
}

.pd-top-05-pc {
  padding-top: 0.5rem;
}

.pd-left-12-pc {
  padding-left: 1.2rem !important;
}

.pd-left-2per-pc {
  padding-left: 2%;
}

.pd-left-4per-pc {
  padding-left: 4%;
}

.pd-right-32 {
  padding-right: 3.2rem;
}

// COLUMN
.col-20 {
  width: 15%;
  max-width: 15%;
}

.col-25 {
  width: 25%;
  max-width: 25%;
}

.col-13 {
  width: 13%;
  max-width: 13%;
}

.col-23 {
  width: 23%;
  max-width: 23%;
}

.col-35 {
  max-width: 35%;
}

.col-40 {
  width: 40%;
}

.col-45 {
  width: 45%;
}

.col-47 {
  width: 47%;
}

.col-55 {
  width: 55%;
}

.col-65 {
  max-width: 65%;
}

.col-50 {
  max-width: 50%;
}

.col-fixed-50 {
  width: 50%;
}

.col-70 {
  max-width: 70%;
}

.col-30 {
  max-width: 30%;
}

.col-fix-30 {
  width: 30%;
}

.col-fix-35 {
  width: 35%;
}

.col-fix-65 {
  width: 65%;
}

.col-15 {
  max-width: 15%;
  flex: 0 0 15%;
}

.col-60 {
  width: 60%;
}

.col-80 {
  width: 80%;
}

.col-85 {
  width: 85%;
}

.col-87-5 {
  width: 87.5%;
}

.col-90 {
  width: 90%;
}

.col-100 {
  width: 100%;
}

.col-min-100 {
  min-width: 100%;
}

.width-115px {
  width: 115px;
}

// FLEX
.flex-wrap {
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}

.flex-1-8 {
  flex: 1.8 !important;
}

// FONTSIZE
.fz-08 {
  font-size: 0.8rem;
}

.fz-08-75 {
  font-size: 0.875rem;
}

.fz-09 {
  font-size: 0.9rem;
}

.fz-07-5 {
  font-size: 0.75rem;
}

.fz-14 {
  font-size: 1.4rem;
}

.fz-15 {
  font-size: 1.5rem;
}

.fz-16 {
  font-size: 1.6rem;
}

.fz-16-25 {
  font-size: 1.625rem;
}

.fz-16-875 {
  font-size: 1.6875rem;
}

.fz-18 {
  font-size: 1.8rem;
}

.fz-18-75 {
  font-size: 1.875rem;
}

.fz-19 {
  font-size: 1.9rem;
}

.fz-17 {
  font-size: 1.7rem;
}

.fz-20 {
  font-size: 2rem;
}

.fz-22 {
  font-size: 2.2rem;
}

.fz-12 {
  font-size: 1.2rem;
}

.fz-12-5 {
  font-size: 1.25rem;
}

.fz-13 {
  font-size: 1.3rem;
}

.fz-13-75 {
  font-size: 1.375rem;
}

.fz-11 {
  font-size: 1.1rem;
}

.fz-11-25 {
  font-size: 1.125rem;
}

.fz-11-5 {
  font-size: 1.15rem;
}

.fz-1 {
  font-size: 1rem;
}

.fz-24 {
  font-size: 2.4rem;
}

.fz-26 {
  font-size: 2.6rem;
}

.fz-25 {
  font-size: 2.5rem;
}

.fz-28 {
  font-size: 2.8rem;
}

.fz-30 {
  font-size: 3rem;
}

.fz-31 {
  font-size: 3.1rem;
}

.fz-32 {
  font-size: 3.2rem;
}

.fz-35 {
  font-size: 3.5rem;
}

.fz-37 {
  font-size: 3.7rem;
}

// ANIMATION
.icon_scale {
  cursor: pointer;
  -webkit-transition: all ease-out 0.3s;
  transition: all ease-out 0.3s;
  -webkit-transform: scale(1);
  transform: scale(1);

  &:hover {
    -webkit-transition: all ease-out 0.3s;
    transition: all ease-out 0.3s;
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
}

.contain_image {
  object-fit: contain;
}

.cover_image {
  object-fit: cover;
}

// Text align
.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-justify {
  text-align: justify;
}

.disabled_user_select {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.color_hover {
  cursor: pointer;

  &:hover {
    color: var(--text-hover-color) !important;
  }
}

.color_hover_green {
  cursor: pointer;

  &:hover {
    color: #009761 !important;
  }
}

// Border
.border-1 {
  border: 1px solid var(--text-color);
}

.border_1_light {
  border: 1px solid var(--border-color);
}

.border_bottom {
  border-bottom: 2px solid var(--border-color);
}

.border_bottom_1 {
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
}

.border_bottom_1_dark {
  border-bottom: 1px solid var(--border-color);
}

.border_bottom_2_black {
  border-bottom: 2px solid var(--black-color);
}

.border_bottom_1_text {
  border-bottom: 1px solid var(--text-color);
}

.border_dashed {
  border-bottom: 1px dashed var(--black-color);
}

.border-1-light {
  border: 1px solid var(--light-border-color);
}

// LIST STYLE
.list_style {
  list-style-type: disc !important;
  list-style: disc !important;
}

.before_dot {
  &::before {
    content: "\2022";
    position: absolute;
    color: black;
    // padding-right: 0.3em;
    top: 0;
    left: 0rem;
    font-size: 1.2rem;
  }
}

.before_dot_assign {
  &::before {
    content: "\2022";
    position: relative;
    color: var(--title-assign-color);
    // padding-right: 0.3em;
    top: 0rem;
    left: 0rem;
    margin-right: 8%;
    margin-left: 4%;
    font-size: 1.3rem;
  }
}

.before_asterisk {
  &::before {
    content: "*";
    position: relative;
    color: var(--light-red-color);
    // padding-right: 0.3em;
    top: 0.2rem;
    left: 0rem;
    margin-right: 0.4rem;
    // margin-left: 4%;
    font-size: 1.3rem;
  }
}

.item_dot_lozenge {
  &::before {
    content: "\25C6";
    position: absolute;
    top: 0.3rem;
    left: -1.2rem;
    color: var(--primary-green);
    font-size: 0.9rem;
  }
}

.item_dot_lozenge_black {
  &::before {
    content: "\25C6";
    position: absolute;
    top: 0rem;
    left: -1.12rem;
    color: var(--black-color);
    font-size: 0.88rem;

    @include screen_mobile {
      font-size: 0.75rem;
    }
  }
}

.item_dot_lozenge_black_top {
  &::before {
    content: "\25C6";
    position: absolute;
    top: 0.7rem;
    left: -1.12rem;
    color: var(--black-color);
    font-size: 0.88rem;

    @include screen_mobile {
      font-size: 0.75rem;
    }
  }
}

.border_radius_05 {
  border-radius: 0.5rem;
}

.border_radius_1 {
  border-radius: 1rem;
}

.text_underline {
  text-decoration: underline !important;
}

.text_underline_offset {
  text-decoration: underline !important;
  text-underline-offset: 0.32rem;

  @include screen_mobile {
    text-underline-offset: 0.2rem;
  }
}

.text_through {
  text-decoration: line-through !important;
}

.pointer_cursor {
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
}

.input_focus {
  &:focus {
    border-color: #333 !important;
  }
}

.ultil_price {
  &::after {
    content: "đ";
    color: var(--light-red-color);
    font-size: 1.5rem;
    position: absolute;
    top: -0.6rem;
    right: -1.1rem;

    @include screen_mobile {
      top: -0.45rem;
      right: -1rem;
      font-size: 1.25rem;
    }
  }
}

.ultil_price_right {
  &::after {
    content: "đ";
    color: var(--light-red-color);
    font-size: 1.2rem;
    position: absolute;
    top: 0;
    right: -1rem;
  }
}

.ultil_price_smaller {
  &::after {
    content: "đ";
    font-size: 1.2rem;
    position: absolute;
    top: -0.2rem;
    right: -1rem;
  }
}

.ultil_price_payment {
  &::after {
    content: "đ";
    font-size: 0.85rem;
    position: absolute;
    top: -0.4rem;
    right: -0.8rem;
  }
}

// POSITION
.pos_rel {
  position: relative;
}

.pos_abs {
  position: absolute;
}

.pos_sticky {
  position: sticky;
  top: 67px;
  z-index: 2;
}

.header_sticky {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  // z-index: 99;
}

.filter_shadow {
  filter: drop-shadow(1px 2px 4px rgba(21, 27, 38, 0.4));
}

.zIndex999 {
  z-index: 999 !important;
}

.bg_readonly {
  // background-color: #cdcdcd !important;
  background-color: #e3e3e3 !important;
}

.notallowed_cursor {
  cursor: not-allowed;
}

// Animation
@keyframes fadeIn {
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.1;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-76vw);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(-85vw);
  }
}

@keyframes slideTopIn {
  from {
    opacity: 0.3;
    transform: translateY(0);
  }

  from {
    opacity: 1;
    transform: translateY(50%);
  }
}

@keyframes scaleInOut {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}

// Other
.img_label_input {
  width: 1.5rem;
  height: auto;

  @include screen_mobile {
    width: 1.2rem;
    height: auto;
  }
}

.img_action_input {
  width: 1.3rem;
  height: auto;

  @include screen_mobile {
    width: 1.1rem;
    height: auto;
  }
}

.rotate_180 {
  transform: rotate(180deg) !important;
}

.keep_all {
  word-break: keep-all;
}

.break_word_all {
  word-break: break-all;
}

.break_word {
  word-break: break-word;
}

.bg-main:has(> .policyPage) {
  background: transparent !important;
}

.bg_white {
  background-color: var(--white-color);
}

.zIndex1 {
  z-index: 1;
}

.zIndex5 {
  z-index: 5;
}

.pd_4per {
  padding: 0 4%;

  @include screen_mobile {
    padding: 0 1.1rem;
  }
}

.pd_6per {
  padding: 0 6%;

  @include screen_mobile {
    padding: 0rem;
  }
}

.pd-hor-11-mobile {
  @include screen_mobile {
    padding-left: 1.1rem;
    padding-right: 1.1rem;
  }
}

.pd-ver-11-mobile {
  @include screen_mobile {
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
  }
}

.pd_8per {
  padding: 0 8%;

  @include screen_mobile {
    padding: 0 2rem;
  }
}

.hover_underline {
  &:hover {
    color: var(--text-hover-color);
    text-decoration: underline !important;
  }
}

.hover_border_bottom {
  &:hover {
    color: var(--text-hover-color) !important;
    border-bottom: 2px solid var(--text-hover-color);
  }
}

.border_after {
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    border-radius: 1rem;
    background-color: var(--primary-green);
    left: 0;
    bottom: -0.3rem;
  }

  &:hover {
    color: var(--primary-green) !important;
  }
}

.border_after_notselected {
  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 3px;
    border-radius: 1rem;
    background-color: var(--primary-green);
    left: 0;
    bottom: -0.3rem;
    transition: 0.2s;
  }

  &:hover {
    color: var(--primary-green) !important;
    font-family: "AvertaStdCY-SemiBold" !important;

    &::after {
      width: 100%;
    }
  }
}

// Font Style
.font_style_italic {
  font-style: italic;
}

// Icon Tick
.icon_tick_success {
  width: 1rem;
  height: auto;
}

.icon_tick_success_over {
  width: 1.375rem;
  height: auto;

  @include screen_mobile {
    width: 1rem;
    margin-top: 0.3rem !important;
  }
}

// List Dot
.list_item_dot {

  li,
  .item_dot {
    &::before {
      content: "\2022";
      color: red;
      padding-right: 0.5em;
      font-size: 1.7rem;

      @include screen_mobile {
        font-size: 1.4rem;
      }
    }
  }
}

// Line Height
.line_height_14 {
  line-height: 1.4rem;
}

.line_height_18-6-6 {
  line-height: 1.86rem;
}

.line_height_normal {
  line-height: normal;
}

// Uppercase
.text_uppercase {
  text-transform: uppercase;
}

.img_intro_item {
  @include screen_mobile {
    width: 2.4rem;
    height: auto;
  }
}

// Pop up Resent
.uk-modal-dialog-resent {
  width: 40% !important;

  @include screen_tablet {
    width: 60% !important;
  }

  @include screen_mobile {
    width: 85% !important;
  }

  .btn_text_popup {
    width: 65% !important;
    min-width: 7rem !important;
    vertical-align: middle;
  }
}

//
.modal_success_abs_container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  text-align: center;

  .modal_success_abs {
    width: 65%;
    min-width: 30rem;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 20%;
    bottom: 0;
    margin: 0 auto;
    text-align: center;

    @include screen_mobile {
      width: 90%;
      min-width: auto;
      top: 16%;
    }

    .modal_container {
      min-height: 45%;
      min-width: 35rem;

      @include screen_mobile {
        // height: 75%;
        min-width: 18rem;
      }
    }
  }
}

// White Space
.white_space_pre {
  white-space: pre;
}

.white_space_pre_wrap {
  white-space: pre-wrap;
}

// Pop up Resent
.uk-modal-dialog-resent {
  width: 40% !important;

  @include screen_tablet {
    width: 60% !important;
  }

  @include screen_mobile {
    width: 85% !important;
  }

  .btn_text_popup {
    width: 65% !important;
    min-width: 7rem !important;
    vertical-align: middle;
  }
}

.uk-modal-dialog-yesno {
  width: 40% !important;
  max-width: 32rem !important;
  min-width: 30rem;

  @media screen and (max-width: 768px) {
    min-width: 22rem;
  }

  .btn_text_popup {
    width: 65% !important;
    min-width: 7rem !important;
    vertical-align: middle;
  }
}

//
.modal_success_abs_container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0 auto;
  text-align: center;

  .modal_success_abs {
    width: 65%;
    min-width: 30rem;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 20%;
    bottom: 0;
    margin: 0 auto;
    text-align: center;

    @include screen_mobile {
      width: 90%;
      min-width: auto;
      top: 16%;
    }

    .modal_container {
      min-height: 45%;

      @include screen_mobile {
        // height: 75%;
      }
    }
  }
}

.flex-evenly {
  display: flex;
  justify-content: space-evenly;
}

// Assign
.btn_next_assign {
  img {
    position: relative;
    left: 0.075rem;
  }
}

.btn_prev_assign {
  img {
    position: relative;
    right: 0.075rem;
  }
}

//Btn log out
.customWidthBtn {
  max-width: 27rem !important;
  min-width: 27rem !important;
  padding: 25px !important;
}

.ico_upgrade {
  width: 80%;
  // height: 80%;
  object-fit: contain;
}

.btn-action-popup {
  max-width: 200px;
  width: 155px !important;
}

.btn-action-popup-smaller {
  max-width: 200px;
  width: 138px !important;
}

.filter-shadow {
  filter: drop-shadow(1px 2px 4px rgba(21, 27, 38, 0.4));
}

.overflow-y-auto {
  overflow-y: auto;
}